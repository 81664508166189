<template>
  <div>
    <v-layout row wrap style="height: 100vh">
      <v-flex xs12 sm6 px-12 :style="$vuetify.breakpoint.xs ? `padding-top:8vh` : `padding-top:17vh`">
        <v-layout row wrap pt-3 :class="{'pl-12': !$vuetify.breakpoint.xs}" >
          <v-flex xs12>
            <b style="font-size: 4em">Matching.HR</b>
          </v-flex>
          <v-flex xs12 mt-8>
            <b style="font-size: 2em">We provide a white-label job-resume matching algorithm to major recruiting software companies.</b>
          </v-flex>
          <v-flex xs12 mt-8>
            <b style="font-size: 1.6em">Display to your clients the top candidates for each job, with intelligent explanations.</b>
          </v-flex>
          <v-flex xs12 mt-8>
            <b style="font-size: 1.6em">Available via a simple plug-and-play API.</b>
          </v-flex>
          <v-flex xs12 mt-8>
            <b style="font-size: 1.4em">Let's talk !</b><br>
            <a style="font-size: 1.4em" :href="mailtoHref">contact@matching.hr</a>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm6 text-center mb-12 pt-7 v-if="!$vuetify.breakpoint.xs">
        <a :href="mailtoHref"><img :src="images.logo" height="60%" style="padding-top:10vh"></a>
      </v-flex>

    </v-layout>
    <v-layout row wrap ml-12 :class="{'pl-12': !$vuetify.breakpoint.xs}" >
      <v-flex xs12>
        <div :style="`position: absolute; bottom: 5px`">
          <small>
            <div>
              <span>Made in Paris, France. – Matching.HR – &copy; Copyright {{ new Date().getFullYear() }} – GDPR compliant.</span>
            </div>
          </small>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
// import axios from "axios";

// const functionsUrlBase = {
//   localhost: "http://localhost:5001/matchinghr-dev/europe-west3",
//   "matchinghr-dev.firebaseapp.com": "https://europe-west3-matchinghr-dev.cloudfunctions.net",
//   "matchinghr-prod.firebaseapp.com": "https://europe-west3-matchinghr-prod.cloudfunctions.net",
//   "matching.hr": "https://europe-west3-matchinghr-prod.cloudfunctions.net",
// }[window.location.hostname];

export default {
  components: {},
  data() {
    return {
      images: {
        logo: require('@/assets/app/images/logo_square.png'),
      },
      mailtoHref: this.getMailToHref()
    }
  },
  methods: {
    getMailToHref() {
      const subject = `Inquiry for Matching.HR`
      const content = `Hello,

We want to provide our users with a better way to identify the right candidates for their jobs.

Would you be available for a video call soon ?

Here are my availabilities:
- 
-
-

Best regards,`
      return `mailto:contact@matching.hr?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(content)}`
    }
  }
};
</script>

<style>
.textAsLink {
  text-decoration: underline !important;
  cursor: pointer !important;
}
</style>