<template>
  <v-app style="background-color:#f4ede6">
    <v-layout row wrap>
      <v-flex xs12>
        <!-- <v-layout row wrap>
          <v-flex xs6>
            <h2>Matching.HR</h2>
          </v-flex>
          <v-flex xs6 text-end pr-6 v-if="user && user.data">
            <small>{{ user.data.email }} - <a @click="signOut" class="textAsLink">Log out</a></small>
          </v-flex>
        </v-layout> -->
      </v-flex>
      <v-flex xs12>
        <v-main>
          <router-view/>
        </v-main>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      images: {
      },
    }
  },
  computed: {
    // ...mapGetters({
    //   user: "user"
    // }),
  },
  methods: {
    // signOut() {
    //   this.$auth.signOut()
    //     .then(() => this.redirectWhenUserStateIsNotLoggedInAnymore())
    // },
    // redirectWhenUserStateIsNotLoggedInAnymore() {
    //   if (this.user.loggedIn) {
    //     setTimeout(() => this.redirectWhenUserStateIsNotLoggedInAnymore(), 10)
    //   } else {
    //     this.$router.push('/')
    //   }
    // },
  }
  
};
</script>


<style>
  .v-btn {
    text-transform: none !important;
  }
  .layout {
    margin: 0 !important;
  }

</style>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }

</style>